import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import * as actions from "../../store/actions/index";
import { searchTypes } from "../../containers/Search/searchConfig";
import classes from "./Layout.module.scss";
import Header from "../../components/Header/Header";
import Menu from "../../components/Navigation/Menu/Menu";
import Footer from "../../components/Footer/Footer";

class Layout extends React.Component {
  state = {};

  componentDidMount() {
    this.props.onLoad();
  }

  search = (searchTerm) => {
    this.props.onSearch(searchTerm);
    this.props.history.push("/search/listings");
  };

  render() {
    return (
      <div className={classes.Layout}>
        <Header
          isAuthenticated={this.props.isAuthenticated}
          onSearch={this.search}
        />
        {this.props.isAuthenticated && <Menu />}

        <main
          className={
            classes.Content +
            (this.props.isAuthenticated
              ? ` ${classes["Content-large-top-margin"]}`
              : "")
          }
        >
          {this.props.children}
        </main>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null && state.auth.emailVerified,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLoad: () => {
      dispatch(actions.loadAllCrystals());
    },
    onSearch: (searchCriteria) =>
      dispatch(
        actions.searchListings(searchCriteria, searchTypes.freeTextSearch)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
