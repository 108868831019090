import React, { useState } from "react";

import classes from "./FreeTextSearch.module.scss";
import FormControlsCreator from "../FormControlsCreator/FormControlsCreator";
import { checkValidity, isFormValid } from "../../shared/utility";
import Button from "../Button/Button";

const FreeTextSearch = (props) => {
  const { onSearch, error } = props;

  const controlsInitialState = {
    searchTerm: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Crystal, colour, shape ...",
      },
      value: "",
      validation: {
        required: true,
        minLength: 3,
      },
      valid: false,
      touched: false,
      orderIndex: 1,
      inputChangedHandler: "inputChangedHandler",
    },
  };

  const [controls, setControls] = useState(controlsInitialState);
  const [formIsValid, setFormIsValid] = useState(true);

  const inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...controls,
      [controlName]: {
        ...controls[controlName],
        value: event.target.value,
        valid: controls[controlName]
          ? checkValidity(event.target.value, controls[controlName].validation)
          : true,
        touched: true,
      },
    };
    setControls(updatedControls);
    setFormIsValid(isFormValid(updatedControls));
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (isFormValid(controls)) {
      onSearch({
        searchTerm: controls.searchTerm.value,
      });
    } else {
      setFormIsValid(false);
    }
  };

  return (
    <form onSubmit={submitHandler} className={classes.FreeTextSearch}>
      <FormControlsCreator
        controls={controls}
        eventHandlers={{
          inputChangedHandler,
        }}
      />
      <Button
        disabled={!formIsValid}
        classNames={`${!formIsValid ? "btn_disabled" : ""}`}
      >
        Search
      </Button>
    </form>
  );
};

export default FreeTextSearch;
