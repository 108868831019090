import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  crystals: [],
  error: null,
  loading: false,
};

const loadAllCrystalsStart = (state, action) => {
  return updateObject(state, { error: null, loading: false });
};

const loadAllCrystalsSuccess = (state, action) => {
  return updateObject(state, {
    crystals: action.crystals,
    error: null,
    loading: false,
  });
};

const loadAllCrystalsFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ALL_CRYSTALS_START:
      return loadAllCrystalsStart(state);
    case actionTypes.LOAD_ALL_CRYSTALS_SUCCESS:
      return loadAllCrystalsSuccess(state, action);
    case actionTypes.LOAD_ALL_CRYSTALS_FAIL:
      return loadAllCrystalsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
