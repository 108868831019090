import React, { useEffect } from "react";
import { connect } from "react-redux";
import { toSentaceCase } from "../../../shared/utility";
import { Link } from "react-router-dom";
import * as actions from "../../../store/actions/index";
import Spinner from "../../../components/Spinner/Spinner";
import { Swiper, SwiperSlide } from "swiper/react";
import MailtoLink from "../../../components/MailtoLink/MailtoLink";
import Container from "../../../hoc/Layout/Container/Container";
import BackLink from "../../../components/BackLink/BackLink";
import classes from "./ListingDetails.module.scss";

import { Navigation, Pagination } from "swiper";

const ListingDetails = (props) => {
  const { onLoad, listing, match } = props;

  useEffect(() => {
    if (match.params.id && match.params.id !== listing.id) {
      onLoad(match.params.id);
    }
  }, [listing.id, match.params.id, onLoad]);

  if (props.loading) {
    return <Spinner />;
  }

  return (
    <Container>
      <div className={classes["Listing-details"]}>
        <BackLink linkText="Go Back" />
        <div className={classes["Listing-details__listing"]}>
          {listing.photos && (
            <Swiper
              navigation={true}
              pagination={true}
              modules={[Pagination, Navigation]}
              className={classes["Listing-details__swiper"]}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {listing.photos &&
                listing.photos.map((photo, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className={classes["Listing-details__swiper-slide"]}>
                        {listing.selling === "yes" && (
                          <div className={classes["Listing-details__forSale"]}>
                            For sale
                          </div>
                        )}
                        <img
                          className={classes["Listing-details__image"]}
                          alt=""
                          src={photo}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          )}

          {/* <img
          className={classes["Listing-details__image"]}
          alt=""
          src={listing.photos[0]}
        /> */}

          <h2 className={classes["Listing-details__title"]}>{listing.title}</h2>

          {listing.selling === "yes" && (
            <h3 className={classes["Listing-details__selling-message"]}>
              {listing.nickname} is selling this crystal. Contact them below!
            </h3>
          )}

          {listing.type && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Type:</div>{" "}
              <div className="Listing-attribute__value">{listing.type}</div>
            </div>
          )}

          {listing.variant && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Variant:</div>
              <div className="Listing-attribute__value">{listing.variant}</div>
            </div>
          )}

          {listing.shape && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Shape:</div>
              <div className="Listing-attribute__value">
                {toSentaceCase(listing.shape)}
              </div>
            </div>
          )}

          {listing.size && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Size:</div>
              <div className="Listing-attribute__value">
                {toSentaceCase(listing.size)}
              </div>
            </div>
          )}

          {listing.dimensions && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Dimensions:</div>
              <div className="Listing-attribute__value">
                {toSentaceCase(listing.dimensions)}
              </div>
            </div>
          )}

          {listing.price && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Price:</div>
              <div className="Listing-attribute__value">£{listing.price}</div>
            </div>
          )}
        </div>

        <div className="Listing-attribute Listing-attribute__label">
          Description
        </div>
        <p className={classes["Listing-details__description"]}>
          {listing.description}
        </p>

        <div className="Listing-attribute Listing-attribute__label">
          Meanings, properties and uses
        </div>
        <p className={classes["Listing-details__meanings"]}>
          {listing.meanings}
        </p>

        {listing.nickname && (
          <div className="Listing-attribute">
            <div className="Listing-attribute__label">Listed by:</div>
            <div className="Listing-attribute__value">
              <Link to={`/${listing.nickname.replace(/\s/g, "")}`}>
                {listing.nickname}
              </Link>
            </div>
          </div>
        )}

        <div className={classes["seller-info-container"]}>
          <div className={classes["seller-info-container__title"]}>
            {listing.nickname ? listing.nickname : listing.listerUserId}{" "}
            {listing.selling === "yes" &&
              "is selling this crystal. Contact them below!"}
          </div>
          <div className={classes["seller-contact-details"]}>
            Contact {listing.nickname ? listing.nickname : listing.listerUserId}
            <MailtoLink
              className={classes["seller-contact-details__email-button-link"]}
              email={listing.email}
              subject={`Crystal connections listing enquiry for listing ${listing.title}`}
              body={createEmailBody(listing)}
            >
              <div className={classes["seller-contact-details__email-button"]}>
                Email
              </div>
            </MailtoLink>
          </div>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    listing: state.listings.listing,
    loading: state.listings.loading,
    error: state.listings.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (listingId) => dispatch(actions.fetchListingById(listingId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingDetails);

function createEmailBody(listing) {
  let emailBody = `Hi ${listing.nickname}, \n\nI am interested in your listing. \n\n`;

  if (listing.title) {
    emailBody += `LISTING TITLE \n${listing.title} \n\n`;
  }

  if (listing.description) {
    emailBody += `LISTING DESCRIPTION \n${listing.description} \n\n`;
  }

  if (listing.price) {
    emailBody += `LISTING PRICE \n${listing.price} \n\n`;
  }

  emailBody += `Please contact me back. \n\nThanks \n\n  \n\nPowered by CRYSTAL CONNECTIONS  https://www.crystalconnections.com \n\n`;

  return emailBody;
}
