import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  email: "",
  firstName: "",
  surname: "",
  nickname: "",
  profilePhoto: "",
  error: "",
  loading: false,
  completedOnBoarding: "false",
};

const fetchProfileStart = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const fetchProfileSuccess = (state, action) => {
  return updateObject(state, {
    ...action.profile,
    error: null,
    loading: false,
  });
};

const fetchProfileFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const updateProfileStart = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const updateProfileFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const updateProfileSuccess = (state, action) => {
  return updateObject(state, {
    ...action.profile,
    error: null,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE_START:
      return fetchProfileStart(state);
    case actionTypes.FETCH_PROFILE_SUCCESS:
      return fetchProfileSuccess(state, action);
    case actionTypes.FETCH_PROFILE_FAIL:
      return fetchProfileFail(state, action);
    case actionTypes.UPDATE_PROFILE_START:
      return updateProfileStart(state);
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return updateProfileSuccess(state, action);
    case actionTypes.UPDATE_PROFILE_FAIL:
      return updateProfileFail(state, action);
    default:
      return state;
  }
};

export default reducer;
