import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import CookieConsent from "react-cookie-consent";

import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import Layout from "./hoc/Layout/Layout";
import Auth from "./containers/Auth/Auth";
import Logout from "./containers/Auth/Logout/Logout";
import Garage from "./containers/Garage/Garage";
import PublicGarage from "./containers/Garage";
import Profile from "./containers/Profile/Profile";
import Listings from "./containers/Listings/Listings";
import SaveListing from "./containers/Listings/SaveListing/SaveListing";
import ListingDetails from "./containers/Listings/ListingDetails/ListingDetails";
import SearchListings from "./containers/Search/Listings/SearchListings";
import * as actions from "./store/actions/index";
import ErrorBoundary from "./ErrorBoundary";
import VerifyEmailAddress from "./containers/Auth/VerifyEmailAddress";
import ForgottenEmail from "./containers/Auth/ForgottenEmail/ForgottenEmail";
import OnBoarding from "./containers/OnBoarding/OnBoarding";

const firebaseConfig = {
  apiKey: "AIzaSyArwTfpihulH7yFt3M5yt8KAXY-0v8E03c",
  authDomain: "crystal-connections.firebaseapp.com",
  projectId: "crystal-connections",
  storageBucket: "crystal-connections.appspot.com",
  messagingSenderId: "299039449672",
  appId: "1:299039449672:web:ad0cfc16a2787234e92e7d",
  measurementId: "G-MFGPT5XEC4",
};

// Check that 'window' is in scope for the analytics module!
if (typeof window !== "undefined" && !getApps().length) {
  initializeApp(firebaseConfig);
  // To enable analytics.
  if ("measurementId" in firebaseConfig) {
    console.log("Google analytics");
    getAnalytics();
  }
}

class App extends React.Component {
  componentDidMount() {
    this.props.onTryAutoSignUp();
  }
  render() {
    let routes = (
      <Switch>
        <Route path="/search/listings" component={SearchListings} />
        <Route path="/verifyemailaddress" component={VerifyEmailAddress} />
        <Route path="/forgottenEmail" component={ForgottenEmail} />
        <Route
          path="/listings/listing/details/:id?"
          component={ListingDetails}
        />
        <Route path="/:nickname" component={PublicGarage} />
        <Route path="/" component={Auth} />
        <Redirect to="/" />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route
            path="/listings/listing/details/:id?"
            component={ListingDetails}
          />
          <Route path="/onboarding" component={OnBoarding} />
          <Route path="/search/listings" component={SearchListings} />
          <Route path="/cave" component={Garage} />
          <Route path="/profile" component={Profile} />
          <Route path="/logout" component={Logout} />
          <Route path="/listings/listing/save/:id?" component={SaveListing} />
          <Route exact path="/listings" component={Listings} />
          <Route path="/:nickname" component={PublicGarage} />
          <Route path="/" component={Auth} />
          <Redirect to="/" />
        </Switch>
      );
    }
    return (
      <Layout>
        <ErrorBoundary>{routes}</ErrorBoundary>
        <CookieConsent>This site uses cookies.</CookieConsent>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null && state.auth.emailVerified,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignUp: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
