import { useState, useCallback } from "react";
import { checkValidity, toSentaceCase } from "../shared/utility";

const useFormSelectCrystalTypeVariant = (initialState, crystals, listing) => {
  const [
    crystalTypeVariantSelectControls,
    setCrystalTypeVariantSelectControls,
  ] = useState(initialState);

  const [currentSelection, setCurrentSelection] = useState({
    type: undefined,
    variant: undefined,
  });

  const intialiseCrystalTypeVariantSelectControls = useCallback(() => {
    let dedupedTypes = getCrystalTypes(crystals);

    setCrystalTypeVariantSelectControls((prevState) => {
      let updatedSelectControls = {
        ...prevState,
        type: {
          ...prevState.type,
          elementConfig: {
            ...prevState.type.elementConfig,
            options: [
              { value: "", displayValue: "Select type" },
              ...dedupedTypes
                .sort((crystalA, crystalB) => {
                  return crystalA.type < crystalB.type
                    ? -1
                    : crystalA.type > crystalB.type
                    ? 1
                    : 0;
                })
                .map((crystal) => {
                  return { value: crystal.type, displayValue: crystal.type };
                }),
            ],
          },
          value: listing.type,
        },
      };

      updatedSelectControls = setCrystalVariant(
        updatedSelectControls,
        crystals,
        listing.type,
        listing.variant
      );

      setCurrentSelection({
        type: listing.type,
        variant: listing.variant,
      });

      return updatedSelectControls;
    });
  }, [listing.type, listing.variant, crystals]);

  const inputCrystalTypeVariantChangeHandler = (event, controlName) => {
    const theEvent = { ...event };
    setCrystalTypeVariantSelectControls((prevState) => {
      let updatedSelectControls;

      switch (controlName) {
        case "type":
          const crystalsByType = getCrystalVariantsByType(
            crystals,
            theEvent.target.value
          );
          setCurrentSelection((prevState) => ({
            type: theEvent.target.value,
            variant: "",
            name: "",
          }));
          updatedSelectControls = {
            ...prevState,
            [controlName]: {
              ...prevState[controlName],
              value: theEvent.target.value,
              valid: prevState[controlName]
                ? checkValidity(
                    theEvent.target.value,
                    prevState[controlName].validation
                  )
                : true,
              touched: true,
            },
            variant:
              theEvent.target.value && crystalsByType.length > 0
                ? {
                    ...prevState.variant,
                    value: "",
                    elementConfig: {
                      ...prevState.type.elementConfig,
                      options: [
                        { value: "", displayValue: "Select variant" },
                        ...crystalsByType
                          .sort((filteredCrystalA, filteredCrystalB) => {
                            return filteredCrystalA.variant <
                              filteredCrystalB.variant
                              ? -1
                              : filteredCrystalA.variant >
                                filteredCrystalB.variant
                              ? 1
                              : 0;
                          })
                          .map((sortedCrystal) => {
                            return {
                              value: sortedCrystal.variant,
                              displayValue: sortedCrystal.variant,
                            };
                          }),
                      ],
                    },
                    disabled: false,
                  }
                : resetControlState(prevState, "variant", true),
          };
          break;
        case "variant":
          setCurrentSelection((prevState) => ({
            ...prevState,
            variant: theEvent.target.value,
            name: "",
          }));
          updatedSelectControls = {
            ...prevState,
            [controlName]: {
              ...prevState[controlName],
              value: theEvent.target.value,
              valid: prevState[controlName]
                ? checkValidity(
                    theEvent.target.value,
                    prevState[controlName].validation
                  )
                : true,
              touched: true,
            },
          };
          break;
        default:
          break;
      }

      return updatedSelectControls;
    });
  };

  return [
    crystalTypeVariantSelectControls,
    intialiseCrystalTypeVariantSelectControls,
    inputCrystalTypeVariantChangeHandler,
  ];
};

export default useFormSelectCrystalTypeVariant;

const getCrystalTypes = (crystals) => {
  return crystals.reduce((acc, current) => {
    let alreadyExists =
      acc.find((item) => item.type === current.type) !== undefined;

    if (!alreadyExists) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
};

const getCrystalVariantsByType = (crylstals, type) => {
  const output = crylstals.reduce((acc, current) => {
    let alreadyExists =
      acc.find(
        (item) => item.type === type && item.variant === current.variant
      ) !== undefined;

    if (current.type === type && !alreadyExists && current.variant !== null) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  return output;
};

const setCrystalVariant = (updatedSelectControls, crystals, type, variant) => {
  let dedupedCrystals = getCrystalVariantsByType(crystals, type);

  const updatedControls = {
    ...updatedSelectControls,
    variant: {
      ...updatedSelectControls.variant,
      disabled: !dedupedCrystals.length > 0,
      elementConfig: {
        ...updatedSelectControls.variant.elementConfig,
        options: [
          { value: "", displayValue: "Select variant" },
          ...dedupedCrystals.map((vehicle) => {
            return { value: vehicle.variant, displayValue: vehicle.variant };
          }),
        ],
      },
      value: variant,
    },
  };

  return updatedControls;
};

const resetControlState = (
  crystalTypeVariantSelectControls,
  controlName,
  disabled = false
) => {
  let resetedControl;

  resetedControl = {
    ...crystalTypeVariantSelectControls[controlName],
    value: "",
    elementConfig: {
      ...crystalTypeVariantSelectControls.type.elementConfig,
      options: [
        { value: "", displayValue: `Select ${toSentaceCase(controlName)}` },
      ],
    },
    disabled: disabled,
    touched: false,
  };

  return resetedControl;
};
