const saveListingControlsConfig = {
  title: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Title",
      maxLength: "50",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    orderIndex: 1,
    inputChangedHandler: "inputChangedHandler",
    label: "Title",
  },
  type: {
    elementType: "select",
    elementConfig: {
      options: [],
    },
    value: "",
    validation: {},
    valid: true,
    orderIndex: 3,
    label: "Type of crystal",
    inputChangedHandler: "inputCrystalTypeVariantChangeHandler",
  },
  variant: {
    elementType: "select",
    elementConfig: {
      options: [{ value: "", displayValue: "Select variant" }],
    },
    value: "",
    validation: {},
    valid: true,
    orderIndex: 4,
    label: "Variant",
    disabled: "disabled",
    inputChangedHandler: "inputCrystalTypeVariantChangeHandler",
  },
  description: {
    elementType: "textarea",
    elementConfig: {
      type: "textarea",
      placeholder: "Description",
      rows: "4",
      maxLength: "1000",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    orderIndex: 5,
    inputChangedHandler: "inputChangedHandler",
    label: "Description",
  },
  meanings: {
    elementType: "textarea",
    elementConfig: {
      type: "textarea",
      placeholder: "Meanings, properties and uses",
      rows: "4",
      maxLength: "1000",
    },
    value: "",
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    orderIndex: 6,
    inputChangedHandler: "inputChangedHandler",
    label: "Meanings, properties and uses",
  },
  price: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Price",
    },
    value: "",
    validation: {
      required: false,
      isNumericTwoDecimalPlaces: true,
    },
    valid: false,
    touched: false,
    orderIndex: 7,
    inputChangedHandler: "inputChangedHandler",
    label: "Price (£)",
  },
  shape: {
    elementType: "select",
    elementConfig: {
      name: "shape",
      options: [
        { value: "", displayValue: "Select shape" },
        {
          value: "Angel Shaped",
          displayValue: "Angel Shaped",
        },
        {
          value: "Animal Shaped",
          displayValue: "Animal Shaped",
        },
        {
          value: "Beads",
          displayValue: "Beads (used in jewellery or for crafting)",
        },
        {
          value: "Candle Holders",
          displayValue:
            "Candle Holders (crystals with a hollowed-out space to hold a candle)",
        },
        {
          value: "Cathedral",
          displayValue: "Cathedral",
        },
        {
          value: "Cathedrals - Other",
          displayValue: "Cathedrals - Other",
        },
        {
          value: "Charging Plates",
          displayValue: "Charging Plates",
        },
        {
          value: "Chunks",
          displayValue: "Chunks",
        },
        {
          value: "Clusters",
          displayValue: "Clusters",
        },
        {
          value: "Crystal Beds",
          displayValue: "Crystal Beds",
        },
        {
          value: "Cube Octahedron",
          displayValue:
            "Cube Octahedron (a cube with each corner removed to create an octahedron shape)",
        },
        {
          value: "Cubes",
          displayValue: "Cubes",
        },
        {
          value: "Dowsing Rods",
          displayValue: "Dowsing Rods",
        },
        {
          value: "Double Terminated points",
          displayValue: "Double Terminated points",
        },
        {
          value: "Eggs",
          displayValue: "Eggs",
        },
        {
          value: "Freeform Shapes / Natural Crystal Formations",
          displayValue:
            "Freeform Shapes / Natural Crystal Formations (e.g., quartz points, amethyst clusters)",
        },
        {
          value: "Gallets",
          displayValue: "Gallets (water-worn stones with a rounded shape)",
        },
        {
          value: "Gemstone Trees",
          displayValue:
            "Gemstone Trees (wire or resin trees with crystal leaves)",
        },
        {
          value: "Geodes",
          displayValue: "Geodes",
        },
        {
          value: "Hearts",
          displayValue: "Hearts",
        },
        {
          value: "Ingot or Bar Shapes",
          displayValue: "Ingot or Bar Shapes",
        },
        {
          value: "Jewellery",
          displayValue:
            "Jewellery (pendants, rings, bracelets, earrings, etc.)",
        },
        {
          value: "Massage Stones",
          displayValue: "Massage Stones",
        },
        {
          value: "Massage Wands",
          displayValue: "Massage Wands",
        },
        {
          value: "Merkaba Stars",
          displayValue: "Merkaba Stars",
        },
        {
          value: "Obelisks",
          displayValue: "Obelisks",
        },
        {
          value: "Orbs / Spheres",
          displayValue: "Orbs / Spheres (Round / Circular Shape)",
        },
        {
          value: "Other",
          displayValue: "Other",
        },
        {
          value: "Palm Stones",
          displayValue: "Palm Stones",
        },
        {
          value: "Pendulums",
          displayValue: "Pendulums",
        },
        {
          value: "Points / Tower",
          displayValue: "Points / Tower",
        },
        {
          value: "Pyramids",
          displayValue: "Pyramids",
        },
        {
          value: "Raw / Rough Stones",
          displayValue: "Raw / Rough Stones",
        },
        {
          value: "Rune Stones",
          displayValue: "Rune Stones",
        },
        {
          value: "Sceptres",
          displayValue: "Sceptres",
        },
        {
          value: "Shaped - Other",
          displayValue: "Shaped - Other",
        },
        {
          value: "Single Terminated Points",
          displayValue: "Single Terminated Points",
        },
        {
          value: "Singing Bowls",
          displayValue: "Singing Bowls (crystal bowls used for sound healing)",
        },
        {
          value: "Skulls",
          displayValue: "Skulls",
        },
        {
          value: "Tumbled Stones",
          displayValue: "Tumbled Stones",
        },
      ],
    },
    value: "new",
    validation: {},
    valid: true,
    orderIndex: 8,
    label: "Shape",
    inputChangedHandler: "inputChangedHandler",
  },
  size: {
    elementType: "select",
    elementConfig: {
      name: "size",
      options: [
        { value: "", displayValue: "Select size" },
        { value: "x-small", displayValue: "Extra small" },
        {
          value: "small",
          displayValue: "Small",
        },
        {
          value: "medium",
          displayValue: "Medium",
        },
        {
          value: "large",
          displayValue: "Large",
        },
        {
          value: "x-Large",
          displayValue: "Extra large",
        },
      ],
    },
    value: "new",
    validation: {},
    valid: true,
    orderIndex: 9,
    label: "Size",
    inputChangedHandler: "inputChangedHandler",
  },
  dimensions: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Dimensions",
      maxLength: "50",
    },
    value: "",
    validation: {},
    valid: true,
    orderIndex: 10,
    inputChangedHandler: "inputChangedHandler",
    label: "Dimensions",
  },
  photos: {
    elementType: "file",
    elementConfig: {
      type: "file",
      multiple: "multiple",
    },
    value: "",
    validation: {
      required: false,
    },
    content: "",
    valid: true,
    touched: false,
    orderIndex: 11,
    inputChangedHandler: "profilePhotoChangedHandler",
    label: "Upload photos",
  },
  active: {
    elementType: "checkbox",
    elementConfig: {
      name: "active",
      options: [{ value: "yes", checked: true }],
    },
    value: "yes",
    validation: {},
    valid: true,
    orderIndex: 12,
    label: "Turn on this listing?",
    inputChangedHandler: "inputCheckboxChangedHandler",
  },
  selling: {
    elementType: "checkbox",
    elementConfig: {
      name: "selling",
      options: [{ value: "yes", checked: true }],
    },
    value: "yes",
    validation: {},
    valid: true,
    orderIndex: 13,
    label: "List this for sale?",
    inputChangedHandler: "inputCheckboxChangedHandler",
  },
  showInGarage: {
    elementType: "checkbox",
    elementConfig: {
      name: "showInGarage",
      options: [{ value: "yes", checked: true }],
    },
    value: "yes",
    validation: {},
    valid: true,
    orderIndex: 14,
    label: "Show this listing in my cave?",
    inputChangedHandler: "inputCheckboxChangedHandler",
  },
};

export default saveListingControlsConfig;
