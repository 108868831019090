import * as actionTypes from "./actionsTypes";
import axios from "axios";
import {
  fetchProfileStart,
  fetchProfileSuccess,
  fetchProfile,
} from "./profile";

export const resetProfileError = () => {
  return {
    type: actionTypes.RESET_PROFILE_ERROR,
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const resendVerificationEmailStart = () => {
  return {
    type: actionTypes.RESEND_VERIFICATION_EMAIL_START,
  };
};

export const resendVerificationEmailSuccess = () => {
  return {
    type: actionTypes.RESEND_VERIFICATION_EMAIL_SUCCESS,
  };
};

export const resendVerificationEmailFail = () => {
  return {
    type: actionTypes.RESEND_VERIFICATION_EMAIL_FAIL,
  };
};

export const resendVerificationEmail = () => {
  const userId = localStorage.getItem("userId");

  return (dispatch) => {
    dispatch(resendVerificationEmailStart());

    return axios
      .get(`/api/user/resendVerificationEmail/${userId}`)
      .then(() => {
        return dispatch(resendVerificationEmailSuccess());
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(resendVerificationEmailFail(err.response.message));
      });
  };
};

export const sendForgottenPasswordEmailStart = () => {
  return {
    type: actionTypes.SEND_FORGOTTEN_PASSWORD_EMAIL_START,
  };
};

export const sendForgottenPasswordEmailSuccess = () => {
  return {
    type: actionTypes.SEND_FORGOTTEN_PASSWORD_EMAIL_SUCCESS,
  };
};

export const sendForgottenPasswordEmailFail = () => {
  return {
    type: actionTypes.SEND_FORGOTTEN_PASSWORD_EMAIL_FAIL,
  };
};

export const sendForgottenPasswordEmail = (email) => {
  return (dispatch) => {
    dispatch(sendForgottenPasswordEmailStart());
    return axios
      .post(`/api/user/sendFogottenPasswordEmail`, { email })
      .then(() => {
        return dispatch(sendForgottenPasswordEmailSuccess());
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(sendForgottenPasswordEmailFail(err.response.message));
      });
  };
};

export const authSuccess = (token, userId, emailVerified, email) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    emailVerified: emailVerified,
    email,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};
export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  localStorage.removeItem("emailVerified");
  localStorage.removeItem("email");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  var expirationTimeInMilliseconds = expirationTime - new Date();
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTimeInMilliseconds);
  };
};

export const auth = (email, password, isSignUp) => {
  return (dispatch) => {
    //authenticate the user
    dispatch(authStart());
    dispatch(fetchProfileStart());
    const authData = {
      email: email,
      password: password,
      returnSecureToken: true,
    };

    let url = "/api/signup";
    if (!isSignUp) {
      url = "/api/login";
    }

    return axios
      .post(url, authData)
      .then((response) => {
        console.log("response --> ", response);
        const expirationDate = new Date(
          response.data.authenticatedUser.stsTokenManager.expirationTime
        ).getTime();
        localStorage.setItem(
          "token",
          response.data.authenticatedUser.stsTokenManager.accessToken
        );
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("userId", response.data.authenticatedUser.uid);
        localStorage.setItem(
          "emailVerified",
          response.data.authenticatedUser.emailVerified
        );
        localStorage.setItem("email", email);
        console.log("success");
        dispatch(
          authSuccess(
            response.data.authenticatedUser.stsTokenManager.accessToken,
            response.data.authenticatedUser.uid,
            localStorage.getItem("emailVerified") === "true",
            email
          )
        );
        dispatch(fetchProfileSuccess(response.data.userProfile));
        dispatch(checkAuthTimeout(expirationDate));
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(authFail(err.response.data.error));
      });
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(
        parseInt(localStorage.getItem("expirationDate"), 10)
      );
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        const userId = localStorage.getItem("userId");
        const emailVerified = localStorage.getItem("emailVerified") === "true";
        const email = localStorage.getItem("email");
        dispatch(authSuccess(token, userId, emailVerified, email));
        dispatch(checkAuthTimeout(expirationDate));
        dispatch(fetchProfile());
      }
    }
  };
};
