import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import classes from "./Listing.module.scss";
import { toSentaceCase } from "../../../shared/utility";

const Listing = ({ listing, isAuthenticated }) => {
  const ListingNotAsLink = ({ listing }) => (
    <Fragment>
      <h3 className="Listing__title">{listing.title}</h3>
      <div
        className={
          listing.selling === "yes"
            ? "Listing__main-content--forSale"
            : "Listing__main-content"
        }
      >
        <div>
          {listing.photos && (
            <img className="Listing__thumb" alt="" src={listing.photos[0]} />
          )}
        </div>
        {listing.selling === "yes" && (
          <h4 className="Listing__selling-message">For sale!</h4>
        )}

        <div>
          {listing.price && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Price:</div>
              <div className="Listing-attribute__value">£{listing.price}</div>
            </div>
          )}
          {listing.type && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Type:</div>
              <div className="Listing-attribute__value">{listing.type}</div>
            </div>
          )}
          {listing.variant && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Variant:</div>
              <div className="Listing-attribute__value">{listing.variant}</div>
            </div>
          )}
          {listing.shape && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Shape:</div>
              <div className="Listing-attribute__value">
                {toSentaceCase(listing.shape)}
              </div>
            </div>
          )}
          {/* {listing.size && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Size:</div>
              <div className="Listing-attribute__value">
                {toSentaceCase(listing.size)}
              </div>
            </div>
          )}
          {listing.dimensions && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Dimensions:</div>
              <div className="Listing-attribute__value">
                {toSentaceCase(listing.dimensions)}
              </div>
            </div>
          )} */}

          {listing.nickname && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Listed by:</div>
              <div className="Listing-attribute__value">
                <Link to={`/${listing.nickname.replace(/\s/g, "")}`}>
                  {listing.nickname}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );

  const ListingAsLink = ({ listing }) => (
    <Link
      className="Listing__link"
      to={`/listings/listing/details/${listing.id}`}
    >
      <ListingNotAsLink listing={listing} />
    </Link>
  );

  return (
    <div className="Listing">
      <ListingAsLink listing={listing} />
    </div>
  );
};

export default Listing;
