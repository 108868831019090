import React from "react";
import classes from "./Error.module.scss";

const Error = ({ errors }) => {
  return (
    errors?.length && (
      <ul className={classes.Error}>
        {errors.map((error) => (
          <li key={error} className={classes.Error__invalidInput}>
            {error}
          </li>
        ))}
      </ul>
    )
  );
};

export default Error;
