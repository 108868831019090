import React from "react";
import { Link } from "react-router-dom";
import classes from "./Listing.module.scss";
import Button from "../../../components/Button/Button";
import { toSentaceCase } from "../../../shared/utility";

const Listing = ({ listing, isAuthenticated }) => {
  return (
    <div className="Listing">
      <h2 className="Listing__title">{listing.title}</h2>
      <div className="Listing__main-content">
        <div>
          {listing.photos && (
            <img className="Listing__thumb" alt="" src={listing.photos[0]} />
          )}
        </div>

        <div>
          {listing.price && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Price:</div>
              <div className="Listing-attribute__value">£{listing.price}</div>
            </div>
          )}
          {listing.type && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Type:</div>
              <div className="Listing-attribute__value">{listing.type}</div>
            </div>
          )}
          {listing.variant && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Variant:</div>
              <div className="Listing-attribute__value">{listing.variant}</div>
            </div>
          )}
          {listing.shape && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Shape:</div>
              <div className="Listing-attribute__value">
                {toSentaceCase(listing.shape)}
              </div>
            </div>
          )}

          {/* {listing.size && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Size:</div>
              <div className="Listing-attribute__value">
                {toSentaceCase(listing.size)}
              </div>
            </div>
          )}

          {listing.dimensions && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Dimensions:</div>
              <div className="Listing-attribute__value">
                {toSentaceCase(listing.dimensions)}
              </div>
            </div>
          )} */}

          <Button className="Listing__button">
            <Link to={`/listings/listing/save/${listing.id}`}>Edit</Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Listing;
