// Footer.js
import React from "react";
import classes from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <a
        href="https://firebasestorage.googleapis.com/v0/b/crystal-connections.appspot.com/o/HowToGuide.pdf?alt=media&token=c8ba399c-bf5c-456e-af87-1ab6f4acaf80"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>
          <strong>How to guide</strong>
        </div>
      </a>
    </footer>
  );
};

export default Footer;
