import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Listing from "../../components/Search/Listing/Listing";
import ListingsContainer from "../../components/Listings/ListingsContainer/ListingsContainer";
import Spinner from "../../components/Spinner/Spinner";
import Container from "../../hoc/Layout/Container/Container";
import classes from "./Garage.module.scss";
import BackLink from "../../components/BackLink/BackLink";

const PublicGarage = (props) => {
  const { onLoad, vehicles, error, loading, match } = props;
  useEffect(() => {
    onLoad(match.params.nickname);
  }, [onLoad, match.params.nickname]);

  const listings = vehicles.map((listing) => {
    return (
      <Listing key={listing.id} listing={listing} isAuthenticated={false} />
    );
  });

  return (
    <Container>
      <section className={classes.Aboutus}>
        <h2>Welcome to {match.params.nickname}(s) Cave</h2>
      </section>

      {error && <p>{error}</p>}

      {loading ? (
        <Spinner />
      ) : (
        <ListingsContainer>{listings}</ListingsContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.garage.loading,
    error: state.garage.error,
    vehicles: state.garage.vehicles,
    userId: state.auth.userId,
    isAuthenticated: state.auth.token !== null && state.auth.emailVerified,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (userId) => dispatch(actions.loadGaragePublic(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicGarage);
