import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Container from "../../hoc/Layout/Container/Container";
import FormControlsCreator from "../../components/FormControlsCreator/FormControlsCreator";
import Spinner from "../../components/Spinner/Spinner";
import { checkValidity, isFormValid } from "../../shared/utility";
import Button from "../../components/Button/Button";
import Error from "../../components/Error/Error";
import * as actions from "../../store/actions/index";

const OnBoarding = (props) => {
  const controlsIntialState = {
    firstName: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "First name",
      },
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      orderIndex: 1,
      label: "First name",
      inputChangedHandler: "inputChangedHandler",
    },
    surname: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Surname",
      },
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      orderIndex: 2,
      label: "Surname",
      inputChangedHandler: "inputChangedHandler",
    },
    nickname: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Nickname",
      },
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      orderIndex: 3,
      label: "Nickname",
      inputChangedHandler: "inputChangedHandler",
    },
  };

  const { loading, error, profile, auth, onSave, onLoad } = props;
  const history = useHistory();
  const [controls, setControls] = useState(controlsIntialState);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);

  const inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...controls,
      [controlName]: {
        ...controls[controlName],
        value: event.target.value,
        valid: controls[controlName]
          ? checkValidity(event.target.value, controls[controlName].validation)
          : true,
        touched: true,
      },
    };
    setControls(updatedControls);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    if (isFormValid(controls)) {
      const updatedProfile = {};
      for (let formElementIdentifier in controls) {
        updatedProfile[formElementIdentifier] =
          controls[formElementIdentifier].value;
      }

      updatedProfile.completedOnBoarding = "true";

      try {
        await onSave(updatedProfile, profile.userId);
        history.push("/");
      } catch (e) {
        console.log(e);
      }
    } else {
      setHasValidationErrors(true);
    }
  };

  useEffect(() => {
    setControls((prevState) =>
      Object.entries(prevState).reduce((obj, control) => {
        var updateControl = {
          ...control[1],
          value: profile[control[0]],
          touched: false,
        };
        obj[control[0]] = updateControl;
        return obj;
      }, {})
    );
  }, [profile]);

  let formControls = (
    <FormControlsCreator
      showvalidationsummary={hasValidationErrors}
      controls={controls}
      eventHandlers={{
        inputChangedHandler,
      }}
    />
  );

  if (loading) {
    formControls = <Spinner />;
  }

  return (
    <Container>
      <h1>Welcome to Crystal Connections</h1>

      <form onSubmit={submitHandler}>
        {formControls}
        {error && (
          <p>
            <Error errors={[error]} />
          </p>
        )}
        <Button classNames={`${hasValidationErrors ? "btn_disabled" : ""}`}>
          -- Complete on boarding --
        </Button>
      </form>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    error: state.profile.error,
    loading: state.profile.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSave: (updateProfile, userId) =>
      dispatch(actions.updateProfile(updateProfile, userId)),
  };
};

//export default OnBoarding;

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding);
