import React from "react";
import classes from "./Logo.module.scss";
import { NavLink } from "react-router-dom";
import companyLogo from "./logo-cropped.png";
//import companyLogo from "./RC_Shake_Transparent_1.png";

const logo = (props) => (
  <div className={classes.Logo}>
    <NavLink to="/" exact>
      <img
        alt="Crystal Connections"
        title="Crystal Connections"
        src={companyLogo}
      ></img>
    </NavLink>
  </div>
);

export default logo;
