import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../store/actions/index";
import useFormSelectCrystalTypeVariant from "../../../hooks/useFormSelectCrystalTypeVariant";
import { searchTypes } from "../../../containers/Search/searchConfig";

import Spinner from "../../../components/Spinner/Spinner";
import Button from "../../../components/Button/Button";
import { checkValidity } from "../../../shared/utility";
import FormControlsCreator from "../../../components/FormControlsCreator/FormControlsCreator";
import Container from "../../../hoc/Layout/Container/Container";
import Listing from "../../../components/Search/Listing/Listing";
import ListingsContainer from "../../../components/Listings/ListingsContainer/ListingsContainer";
import classes from "./SearchListings.module.scss";

const SearchListings = (props) => {
  const {
    onSearch,
    onShowAdvancedSearch: onShowHideAdvancedSearch,
    onReinitialiseListingsState,
    listingsSearchResults,
    crystals,
    loading,
    searchType,
    error,
    isAuthenticated,
  } = props;
  const controlsInitialState = {
    type: {
      elementType: "select",
      elementConfig: {
        options: [],
      },
      value: "",
      validation: {},
      valid: true,
      orderIndex: 4,
      label: "Type of crystal",
      inputChangedHandler: "inputCrystalTypeVariantChangeHandler",
    },
    variant: {
      elementType: "select",
      elementConfig: {
        options: [{ value: "", displayValue: "Select variant" }],
      },
      value: "",
      validation: {},
      valid: true,
      orderIndex: 5,
      label: "Variant",
      disabled: "disabled",
      inputChangedHandler: "inputCrystalTypeVariantChangeHandler",
    },
  };

  const listings = listingsSearchResults.map((listing) => {
    return (
      <Listing
        key={listing.id}
        listing={listing}
        isAuthenticated={isAuthenticated}
      />
    );
  });

  const typeVariantControlsInitState = (({ type, variant }) => ({
    type,
    variant,
  }))(controlsInitialState);

  const [
    crystalTypeVariantSelectControls,
    intialiseCrystalTypeVariantSelectControls,
    inputCrystalTypeVariantChangeHandler,
  ] = useFormSelectCrystalTypeVariant(
    typeVariantControlsInitState,
    crystals,
    {}
  );

  // const inputChangedHandler = (event, controlName) => {
  //   const updatedControls = {
  //     ...otherControls,
  //     [controlName]: {
  //       ...otherControls[controlName],
  //       value: event.target.value,
  //       valid: otherControls[controlName]
  //         ? checkValidity(
  //             event.target.value,
  //             otherControls[controlName].validation
  //           )
  //         : true,
  //       touched: true,
  //     },
  //   };
  //   setControls(updatedControls);
  // };

  const submitHandler = (event) => {
    event.preventDefault();

    let searchCriteria = {};
    const { type, variant } = {
      ...crystalTypeVariantSelectControls,
    };
    searchCriteria = {
      type: type.value,
      variant: variant.value,
    };

    onSearch(searchCriteria);
  };

  const showAdvancedSearch = (event) => {
    event.preventDefault();
    onShowHideAdvancedSearch(
      searchType === "freeTextSearch" ? "specificSearch" : "freeTextSearch"
    );
  };

  let form = (
    <>
      <h3>By type and variant</h3>
      <FormControlsCreator
        controls={crystalTypeVariantSelectControls}
        submitHandler={submitHandler}
        eventHandlers={{
          inputCrystalTypeVariantChangeHandler,
        }}
      />
    </>
  );

  useEffect(() => {
    intialiseCrystalTypeVariantSelectControls();
  }, [intialiseCrystalTypeVariantSelectControls, onReinitialiseListingsState]);

  return (
    <Container>
      {!loading ? (
        <h2>{listings.length} listings found.</h2>
      ) : (
        <h2>Searching for listings ......</h2>
      )}
      <div className={classes.SearchLinks}>
        <Link to="#" onClick={showAdvancedSearch}>
          {searchType === "freeTextSearch"
            ? "Advanced search"
            : "Hide Advanced search"}
        </Link>
      </div>
      {searchType !== "freeTextSearch" && (
        <form className={classes.AdvancedSearch} onSubmit={submitHandler}>
          {form}
          <Button>-- SEARCH --</Button>
        </form>
      )}

      {loading ? (
        <Spinner />
      ) : (
        <ListingsContainer>{listings}</ListingsContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    crystals: state.crystals.crystals,
    listingsSearchResults: state.search.listingsSearchResults,
    loading: state.search.loading,
    searchType: state.search.searchType,
    error: state.search.error,
    isAuthenticated: state.auth.token !== null && state.auth.emailVerified,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSearch: (searchCriteria) =>
      dispatch(
        actions.searchListings(searchCriteria, searchTypes.specificSearch)
      ),
    onShowAdvancedSearch: (searchType) =>
      dispatch(actions.changeSearchType(searchType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchListings);
