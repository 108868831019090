import React from "react";
import Button from "../Button/Button";
import classes from "./BackLink.module.scss";
import { useHistory } from "react-router-dom";

const BackLink = ({ linkText }) => {
  const history = useHistory();
  return history.length > 1 ? (
    <div className={classes.BackLink}>
      <Button clicked={history.goBack}>{linkText || "Go Back"}</Button>
    </div>
  ) : null;
};

export default BackLink;
