import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import useResetProfileError from "../../hooks/useResetProfileError";
import { checkValidity, isFormValid } from "../../shared/utility";
import Container from "../../hoc/Layout/Container/Container";
import FormControlsCreator from "../../components/FormControlsCreator/FormControlsCreator";
import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner/Spinner";
// import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { Link } from "react-router-dom";
import classes from "./Auth.module.scss";
import Error from "../../components/Error/Error";
import * as actions from "../../store/actions/index";

const Auth = (props) => {
  const {
    authRedirectPath,
    onSetAuthRedirectPath,
    onAuth,
    loading,
    error,
    isAuthenticated,
    isEmailVerified,
    onLoad,
    onResendVerificationEmail,
    profile,
  } = props;
  const controlsInitialState = {
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        placeholder: "Mail Address",
        autoComplete: "username",
      },
      value: "",
      validation: {
        required: true,
        isEmail: true,
      },
      valid: false,
      touched: false,
      orderIndex: 1,
      inputChangedHandler: "inputChangedHandler",
    },
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Password",
        autoComplete: "password",
      },
      value: "",
      validation: {
        required: true,
        minLength: 6,
      },
      valid: false,
      touched: false,
      orderIndex: 2,
      inputChangedHandler: "inputChangedHandler",
    },
  };

  const [controls, setControls] = useState(controlsInitialState);
  const [isSignup, setIsSignup] = useState(false);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);

  useEffect(() => {
    if (authRedirectPath !== "/") {
      onSetAuthRedirectPath();
    }
  }, [authRedirectPath, onSetAuthRedirectPath]);

  useResetProfileError();

  const inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...controls,
      [controlName]: {
        ...controls[controlName],
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          controls[controlName].validation
        ),
        touched: true,
      },
    };
    setControls(updatedControls);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (isFormValid(controls)) {
      onAuth(controls.email.value, controls.password.value, isSignup).then(
        () => {
          console.log("hello");
        }
      );
    } else {
      setHasValidationErrors(true);
    }
  };

  const resendEmailSubmitHandler = (event) => {
    event.preventDefault();
    onResendVerificationEmail();
  };

  const switchAuthModeHandler = (e) => {
    e.preventDefault();
    setIsSignup((prevState) => {
      return !prevState;
    });
  };

  let form = (
    <FormControlsCreator
      showValidationSummary={hasValidationErrors}
      controls={controls}
      eventHandlers={{
        inputChangedHandler,
      }}
    />
  );

  if (loading) {
    form = <Spinner />;
  }

  let errorMessage = null;

  if (error) {
    errorMessage = <Error errors={[error]} />;
  }

  let redirect = null;
  if (!profile.loading) {
    if (
      isAuthenticated &&
      isEmailVerified &&
      profile.completedOnBoarding === "false"
    ) {
      redirect = <Redirect to={"/onboarding"} />;
    } else if (isAuthenticated && isEmailVerified) {
      redirect = <Redirect to={"/listings"} />;
    }
  }

  return (
    <>
      {/* <div className={classes.Auth}> */}
      <Container>
        {isAuthenticated && !isEmailVerified && (
          <>
            <div>
              Please check your email and verify your email address to complete
              your registration!
            </div>
            <form onSubmit={resendEmailSubmitHandler}>
              <Button>-- RESEND --</Button>
            </form>
          </>
        )}
        <>
          {redirect}
          <h1>{isSignup ? "Sign up" : "Log in"}</h1>
          {errorMessage}
          <form onSubmit={submitHandler}>
            {form}
            <Button classNames={`${hasValidationErrors ? "btn_disabled" : ""}`}>
              {isSignup ? "Sign up" : "Login"}
            </Button>
            <div className={classes.Authform_links}>
              <Link to="/forgottenEmail">Forgotten password?</Link>
            </div>
            <div className={classes.Authform_links}>
              <Link to="#" onClick={switchAuthModeHandler}>
                {isSignup ? (
                  <div>
                    Already a member? - <strong>LOG IN</strong>
                  </div>
                ) : (
                  <div>
                    Become a member? - <strong>SIGN UP</strong>
                  </div>
                )}
              </Link>
            </div>
          </form>
        </>

        <section className={classes.Aboutus}>
          <h2>Crystal Connections</h2>
          <ul className={classes.WhatTheSiteDoes}>
            <li>
              Create a personal catalogue of your crystal collection and
              showcase them to others.
            </li>
            <li>
              Document their size, shape, meanings and uses, for ease of
              reference and use.
            </li>
            <li>Save your favourite crystals in ‘My Crystal Cave’.</li>
            <li>
              Privately sell any of your crystals to other enthusiasts (like
              Gumtree but for crystals!)
            </li>
            <li>
              Directly email other users to ask questions and purchase crystals.
            </li>
          </ul>
          {/* <Accordion>
            <AccordionItem header="About us"> */}
          <h3>About Us</h3>
          <p>
            Crystals, with their unique and abundant qualities, play a special
            role in enhancing the flow of life. They provide emotional support,
            strength, and healing. I’m Claire Donaldson, a devoted crystal
            enthusiast and a certified crystal healing therapist. I believe in
            the extraordinary properties of crystals to facilitate healing for
            the body and the environment, fostering peace, tranquillity, and
            heightened energy.
          </p>
          <p>
            There are so many different and varied crystals, with numerous
            meanings, properties and uses around these days, that it has proven
            useful to have a personal log and clear reference guide to the
            crystals you own. This need led to the creation of "Crystal
            Connections."
          </p>
          <p>
            Crystals tirelessly channel their energies to individuals and
            spaces, contributing to healing and support. Sometimes, after
            fulfilling their purpose or completing their time with a particular
            person or place, crystals may need to find new homes. “Crystal
            Connections” allows you to privately sell crystals you no longer
            need and communicate with other users.
          </p>
          <p>
            Collaborating with Kamran Sarpash, a skilled web and app developer,
            we’ve created this online platform, for fellow crystal enthusiasts.
          </p>
          <p>
            In the initial phase of Crystal-Connections.com you can create a
            personal crystal page (catalogue) in ‘My Crystal Cave’ and list
            crystals for sale in ‘My Listings.’ Searching crystals that you are
            looking for to purchase from others and directly emailing other
            members is also possible. We are committed to expanding the site
            with new features and welcome your feedback to enhance its
            functionality.
          </p>
          <p>
            We hope{" "}
            <a href="https://www.crystalconnections.co.uk/">
              Crystal Connections
            </a>{" "}
            becomes a valuable resource for expressing your love and
            appreciation of crystals and allowing you to share your passion with
            others.{" "}
          </p>
          {/* </AccordionItem>
          </Accordion> */}
        </section>
      </Container>
      {/* </div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    isEmailVerified: state.auth.emailVerified == true,
    authRedirectPath: state.auth.authRedirectPath,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, isSignup) =>
      dispatch(actions.auth(email, password, isSignup)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
    onResendVerificationEmail: () =>
      dispatch(actions.resendVerificationEmail("/")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
