import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Listing from "../../components/Search/Listing/Listing";
import ListingsContainer from "../../components/Listings/ListingsContainer/ListingsContainer";
import Spinner from "../../components/Spinner/Spinner";
import Container from "../../hoc/Layout/Container/Container";
import classes from "./Garage.module.scss";

const Garage = (props) => {
  const { onLoad, userId, vehicles, error, loading, isAuthenticated } = props;
  useEffect(() => {
    onLoad(userId);
  }, [onLoad, userId]);

  const listings = vehicles.map((listing) => {
    return (
      <Listing
        key={listing.id}
        listing={listing}
        isAuthenticated={isAuthenticated}
      />
    );
  });

  return (
    <Container>
      <section className={classes.Aboutus}>
        <h2>The Crystal Cave</h2>
        <p>Showcase your most unique and rare crystals in "My Cave".</p>
        <p>
          Tick the "Show this listing in my cave?" checkbox on the My Listings
          screen to showcase your crystals here.
        </p>
      </section>

      {loading ? (
        <Spinner />
      ) : (
        <ListingsContainer>{listings}</ListingsContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.garage.loading,
    error: state.garage.error,
    vehicles: state.garage.vehicles,
    userId: state.auth.userId,
    isAuthenticated: state.auth.token !== null && state.auth.emailVerified,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (userId) => dispatch(actions.loadGarage(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Garage);
