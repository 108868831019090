import * as actionTypes from "./actionsTypes";
import axios from "axios";

export const loadAllCrystalsStart = () => {
  return {
    type: actionTypes.LOAD_ALL_CRYSTALS_START,
  };
};

export const loadAllCrystals = () => {
  // const token = localStorage.getItem("token");
  // axios.defaults.headers.common = { Authorization: `${token}` };

  return (dispatch) => {
    dispatch(loadAllCrystalsStart());

    return axios
      .get("/api/crystals")
      .then((response) => {
        return dispatch(loadAllCrystalsSuccess(response.data));
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(loadAllCrystalsFail(err.message));
      });
  };
};

export const loadAllCrystalsSuccess = (crystals) => {
  return {
    type: actionTypes.LOAD_ALL_CRYSTALS_SUCCESS,
    crystals: crystals,
  };
};

export const loadAllCrystalsFail = (error) => {
  return {
    type: actionTypes.LOAD_ALL_CRYSTALS_FAIL,
    error: error,
  };
};
